.sidebar-form {
  position: fixed;
  top: 50%;
  right: -350px;
  width: 350px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  padding: 16px;
  transform: translateY(-50%);
  transition: right 0.4s linear;
  z-index: 1;
}

.sidebar-form.show {
  right: 0;
}

.sidebar-form .call-action {
  position: absolute;
  background-color: #95bf47;
  font-family: "Roboto-Regular";
  letter-spacing: 1px;
  color: white;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  left: -105px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
}

.sidebar-form h4 {
  font-family: "Roboto-Bold";
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 0px;
  text-align: center;
  color: Black;
}

.sidebar-form .form-group {
  margin-bottom: 6px;
}

.sidebar-form .form-group label {
  font-family: "roboto";
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 2px;
  color: #333;
}

.sidebar-form #submit-btn {
  width: 100%;
  border: none;
  margin-top: 8px;
  background-color: #95bf47;
  color: white;
  padding: 5px;
  font-family: "Roboto-Regular";
  font-weight: 400;
  cursor: pointer;
  font-size: 12.5px;
}

@media only screen and (min-width: 321px) and (max-width: 600px) {
  .sidebar-form {
    width: 340px;
  }
}

@media screen and (max-width: 320px) {
  .sidebar-form {
    width: 90%;
  }

  .sidebar-form .call-action {
    left: -160px;
  }
}

/* From Uiverse.io by boryanakrasteva */
/* Customize the label (the checkbox-btn) */
.sidebar-form .checkbox-btn {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.sidebar-form .checkbox-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.sidebar-form .checkbox-btn label {
  cursor: pointer;
  font-size: 14px;
}
/* Create a custom checkbox */
.sidebar-form .checkmark {
  position: absolute;
  top: 3.5px;
  left: 0px;
  height: 13px;
  width: 13px;
  border: 1px solid #333;
  transition: 0.2s linear;
}
.sidebar-form .checkbox-btn input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.sidebar-form .checkmark:after {
  content: "";
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  top: 25%;
  width: 10px;
  height: 16px;
  border: 2px solid #95bf47;
  filter: drop-shadow(0px 0px 10px #95bf47);
  border-width: 0 2.5px 2.5px 0;
  transition: 0.2s linear;
  transform: translate(-50%, -50%) rotate(-90deg) scale(0.2);
}

/* Show the checkmark when checked */
.sidebar-form .checkbox-btn input:checked ~ .checkmark:after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -50%) rotate(0deg) scale(1);
  animation: pulse 1s ease-in;
}

.sidebar-form .checkbox-btn input:checked ~ .checkmark {
  transform: rotate(45deg);
  border: none;
}

@keyframes pulse {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1.6);
  }
}
