/* ------GLOBAL STYLING------- */

/* Custom Button Styling */
.global-btn-bg {
  background-color: #95bf47;
  color: white;
  padding: 8px 25px;
  border-radius: 10px;
  border: none;
  width: fit-content;
  font-family: 'Roboto-Regular';
}

.global-btn {
  background-color: transparent;
  color: #95bf47;
  padding: 8px 25px;
  border-radius: 10px;
  border: 1px solid #95bf47;
  width: fit-content;
  font-family: 'Roboto-Regular';
}

@media screen and (max-width: 320px) {
  .global-btn-bg {
    font-size: 14px;
  }

  .global-btn {
    font-size: 14px;
  }
}

@keyframes upndown {
  from {
    transform: translateY(5px);
  }

  to {
    transform: translateY(-40px);
  }
}

/* Custom Button Styling */

.custom-theme {
  color: #95bf47;
}

.custom-theme-gray {
  color: #2f3640;
}

.custom-theme-lighgray {
  color: #4e4e4e;
}

/* Poppin Font */
.font-face-Regular {
  font-family: 'Roboto-Regular';
}

.font-face-Bold {
  font-family: 'Roboto-Bold';
}

.font-face-Italic {
  font-family: 'Roboto-Italic';
}

.wrapper .button.btn-1 {
  display: inline-block;
  height: 60px;
  width: 60px;
  float: left;
  margin: 0 5px;
  overflow: hidden;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}

.wrapper .button.btn-2 {
  display: inline-block;
  height: 60px;
  width: 60px;
  float: left;
  margin: 0 5px;
  overflow: hidden;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
}

.wrapper .button.btn-1:hover {
  width: 210px;
  background-color: #0087d7;
}

.wrapper .button.btn-2:hover {
  width: 210px;
  background-color: #00d670;
}

.wrapper .button.btn-1 .first-icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3s ease-out;
  background-color: #0087d7;
}

.wrapper .button.btn-2 .second-icon {
  display: inline-block;
  height: 60px;
  width: 60px;
  text-align: center;
  border-radius: 50px;
  box-sizing: border-box;
  line-height: 60px;
  transition: all 0.3s ease-out;
  background-color: #00d670;
}

.wrapper .button.btn-1:hover .icon {
  background: #25d366;
}

.wrapper .button.btn-2:hover .icon {
  background: #1da1f2;
}

.wrapper .button.btn-1 .icon i {
  font-size: 25px;
  line-height: 60px;
  transition: all 0.3s ease-out;
}

.wrapper .button.btn-2 .icon i {
  font-size: 25px;
  line-height: 60px;
  transition: all 0.3s ease-out;
}

.wrapper .button.btn-1:hover .icon i {
  color: #fff;
}

.wrapper .button.btn-2:hover .icon i {
  color: #fff;
}

.wrapper .button.btn-1 span {
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}

.wrapper .button.btn-2 span {
  font-size: 20px;
  font-weight: 500;
  line-height: 60px;
  margin-left: 10px;
  transition: all 0.3s ease-out;
}

.wrapper .btn-1 span {
  color: white;
}

.wrapper .btn-2 span {
  color: white;
}

@media screen and (max-width: 540px) {
  .mobile-heading {
    max-width: 80%;
  }

  .mobile-paragraph {
    max-width: 90%;
  }
}

@keyframes updownresponsive {
  from {
    transform: translateY(25px);
  }

  to {
    transform: translateY(1px);
  }
}

/* --------REMOVE INLINE CSS AND MAKE IT EXTERNAl FROM HERE--------- */

.layout-left-icon {
  position: fixed;
  bottom: 110px;
  left: 20px;
  z-index: 9999;
  height: 50px;
  width: 50px;
}

.layout-left-icon-second {
  position: fixed;
  bottom: 30px;
  left: 20px;
  z-index: 9999;
  height: 50px;
  width: 50px;
}

.global-anchor {
  color: white;
  text-decoration: none;
}

.updown-animation {
  animation: updownresponsive 2s linear infinite alternate;
}

.topbottm-animation {
  animation: upndown 2s linear infinite alternate;
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ensure full viewport height */
  color: #95bf47;
}
